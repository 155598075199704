import React, { useState } from "react"
import axios from "axios"

import Input from "components/Input"
import Checkbox from "components/Checkbox"
import Button from "components/Button"
import Select from "components/Select"

const NewContractForm = ({ customer }) => {
  const formID = "192"
  const [send, setSend] = useState(false)
  const [name, setName] = useState(
    `${customer?.firstName || ""} ${customer?.lastName || ""}`
  )
  const [email, setEmail] = useState(customer?.email || "")
  const [phone, setPhone] = useState(customer?.phone || "")
  const [amount, setAmount] = useState(null)
  const [message, setMessage] = useState(
    `Proszę o kontakt w sprawie inwestycji ...`
  )
  const [legalFirst, setLegalFirst] = useState(false)

  const formSubmit = e => {
    e.preventDefault()
    setSend(true)

    let formData = new FormData()

    formData.set("firstName", name)
    formData.set("email", email)
    formData.set("phone", phone)
    formData.set("typePhone", "Telefon")
    formData.set("message", message)
    formData.set("date", new Date().toLocaleString() + "")
    formData.set("legalFirst", legalFirst)

    axios({
      method: "post",
      url: `https://trust-project-14.zenx.pl/wp-json/contact-form-7/v1/contact-forms/${formID}/feedback/`,
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(() => {
        console.log("Submit success")
      })
      .catch(err => {
        console.log(err)
      })
  }

  return (
    <form onSubmit={formSubmit}>
      <Input
        placeholder="imię i nazwisko*"
        type="text"
        id="name"
        name="name"
        value={name}
        onChange={e => setName(e.target.value)}
        required
      />

      <div className="row align-items-center">
        <div className="col-sm-6">
          <Input
            placeholder="telefon*"
            type="phone"
            id="phone"
            name="phone"
            value={phone}
            onChange={e => setPhone(e.target.value)}
            required
          />
        </div>
        <div className="col-sm-6">
          <Input
            placeholder="e-mail*"
            type="email"
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
            id="email"
            name="email"
            value={email}
            onChange={e => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="col-sm-6">
          <Select
            className="no-label"
            options={[
              {
                label: "Nazwa inwestycji",
                value: "",
                disabled: true,
              },
              {
                label: "Sky Trust etap II",
                value: "Sky Trust etap II",
              },
            ]}
          />
        </div>
        <div className="col-sm-6">
          <Input
            placeholder="Kwota do zainwestowania"
            type="number"
            id="amount"
            name="amount"
            value={amount}
            onChange={e => setAmount(e.target.value)}
          />
        </div>
        <div className="col-sm-6">
          <div className="customer-panel-popup__type">
            <strong>Preferowana format kontaktu</strong>
            <div className="row">
              <div className="col-sm-6">
                <Checkbox
                  className="checkbox--regular"
                  name="type"
                  checked={true}
                >
                  Telefon
                </Checkbox>
              </div>
              <div className="col-sm-6">
                <Checkbox className="checkbox--regular" name="type" disabled>
                  Wiadomość email
                </Checkbox>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Input
        placeholder="treść wiadomości"
        type="textarea"
        id="message"
        name="message"
        value={message}
        onChange={e => setMessage(e.target.value)}
        rows="5"
      />

      <div className="contact-form__action">
        <Checkbox
          className="checkbox--regular"
          name="legalFirst"
          checked={legalFirst}
          onChange={() => setLegalFirst(!legalFirst)}
        >
          Wyrażam dobrowolną zgodę na przetwarzanie moich danych osobowych przez
          Trust Investment Projekt 14 sp. z o.o., ul. Robotnicza 1, 25-662
          Kielce NIP: 9592051928, REGON: 521970376, KRS: 0000970028
          (Administrator danych), w celu marketingu bezpośredniego dotyczącego
          własnych produktów i usług. Obowiązek informacyjny zgodnie z art. 13
          ogólnego rozporządzenia o ochronie danych osobowych (RODO) z dnia 27
          kwietnia 2016 r. (Dz. Urz. UE L 119 z 04.05.2016): informacje o
          przetwarzaniu przez nas danych, jak również o prawach osób, których
          dane dotyczą znajdują się w naszej polityce prywatności.
        </Checkbox>
        <Button className="button" type="submit" disabled={!legalFirst}>
          {send === true ? "Wysyłanie.." : "Wyślij"}
        </Button>
      </div>
    </form>
  )
}

export default NewContractForm
